import React from 'react';
import cn from 'classnames';
import {
  toast,
  ToastContainer,
  ToastContainerProps,
  ToastOptions,
} from 'react-toastify';
import { isTablet, isMobile } from 'react-device-detect';

import 'react-toastify/dist/ReactToastify.css';
import styles from './Toast.module.scss';

interface ShowToastParams extends ToastOptions {
  message?: string;
}

const defaultPosition = 'top-right';
const defaultAutoClose = 4000;
const defaultHideProgressBar = false;
const defaultCloseOnClick = true;
const defaultPauseOnHover = true;
const defaultDraggable = true;

export function showToast({
  message,
  type = 'success',
  position = defaultPosition,
  autoClose = defaultAutoClose,
  hideProgressBar = defaultHideProgressBar,
  closeOnClick = defaultCloseOnClick,
  pauseOnHover = defaultPauseOnHover,
  draggable = defaultDraggable,
  ...rest
}: ShowToastParams): void {
  toast[type](!message?.length ? 'Something went wrong' : message, {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    ...rest,
  });
}

const Toast: React.FC<ToastContainerProps> = ({
  position = defaultPosition,
  autoClose = defaultAutoClose,
  hideProgressBar = defaultHideProgressBar,
  closeOnClick = defaultCloseOnClick,
  pauseOnHover = defaultPauseOnHover,
  draggable = defaultDraggable,
  newestOnTop = true,
  rtl = false,
}) => (
  <ToastContainer
    className={cn(styles.toastWrapper, {
      [styles.toastWrapperMobile]: isTablet || isMobile,
    })}
    toastClassName={styles.toastContainer}
    bodyClassName={styles.toastBody}
    position={position}
    autoClose={autoClose}
    hideProgressBar={hideProgressBar}
    closeOnClick={closeOnClick}
    pauseOnHover={pauseOnHover}
    draggable={draggable}
    newestOnTop={newestOnTop}
    rtl={rtl}
  />
);

export default Toast;
