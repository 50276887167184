export const debounce = (func: (...args: any) => unknown, ms = 300) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return function executedFunction(...args: any) {
    const later = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, ms);
  };
};
