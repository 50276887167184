const SCRIPT = 'script';
const SKD_ID = 'gapi-client';

export type GoogleLoginSuccessResult = {
  code: string;
};

export const initGoogleSDK = ({ appId }: { appId: string }): Promise<void> =>
  new Promise((resolve, reject) => {
    const onLoad = () => {
      window.gapi &&
        window.gapi.load('auth2', () => {
          window.gapi.auth2
            .init({
              client_id: appId,
              fetch_basic_profile: true,
            })
            .then(
              () => resolve(),
              (err) =>
                reject({
                  message: 'Failed to load SDK',
                  originalError: err,
                })
            );
        });
    };

    ((d: typeof document, s: string, id: string): void => {
      const firstJs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
        return;
      }

      const jsSdk: any = d.createElement(s);
      jsSdk.src = 'https://apis.google.com/js/platform.js';
      jsSdk.id = id;
      jsSdk.onload = onLoad;
      jsSdk.async = true;
      jsSdk.defer = true;

      firstJs.parentNode?.insertBefore(jsSdk, firstJs);
    })(document, SCRIPT, SKD_ID);
  });

export const login = (): void => {
  const GoogleAuth = window.gapi?.auth2?.getAuthInstance();
  const redirectUri =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : process.env.NEXT_PUBLIC_BASE_PATH;

  GoogleAuth?.grantOfflineAccess({
    prompt: 'select_account',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ux_mode: 'redirect',
    redirect_uri: redirectUri,
    scope:
      'https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read  https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/userinfo.profile openid',
  });
};

export const loginForReStreaming = (): Promise<GoogleLoginSuccessResult> => {
  const GoogleAuth = window.gapi?.auth2?.getAuthInstance();

  return GoogleAuth?.grantOfflineAccess({
    prompt: 'consent',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ux_mode: 'popup',
    scope:
      'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.profile',
  });
};

export const logOut = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const GoogleAuth = window.gapi?.auth2?.getAuthInstance();

    if (GoogleAuth?.isSignedIn?.get()) {
      GoogleAuth.signOut().then(resolve, reject);
    } else {
      resolve();
    }
  });
