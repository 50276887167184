// Helpers
import { getEnvLink } from 'helpers/routes';
import { isBuildInBrowser } from 'helpers/userAgent';

const SCRIPT = 'script';
const SDK_ID = 'facebook-jssdk';
const facebookScopes = ['public_profile', 'email'];
const facebookScopesForReStreaming = [
  'public_profile',
  'publish_video',
  'pages_read_engagement',
  'pages_manage_posts',
  'pages_show_list',
];
const facebookStoreScopes = [
  'public_profile',
  'email',
  'pages_show_list',
  'instagram_basic',
  'user_link',
  'user_gender',
  'user_hometown',
  'user_birthday',
];

export enum FacebookLoginStatuses {
  Connected = 'connected',
  NotAuthorized = 'not_authorized',
  Unknown = 'unknown',
}

export type FacebookLoginSuccessResult = {
  profile: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    profilePicURL: string;
  };
  token: {
    accessToken: string;
    expiresAt: number;
  };
};

export type FacebookGetPagesSuccessResult_data = {
  id: string;
  name: string;
};

export type FacebookGetPagesSuccessResult = {
  data: FacebookGetPagesSuccessResult_data[];
  paging: {
    cursors: {
      before: string;
      after: string;
    };
  };
};

export const loadFacebookSdk = (
  d: typeof document,
  s: string,
  id: string
): void => {
  const firstJs = d.getElementsByTagName(s)[0];

  if (d.getElementById(id)) {
    return;
  }

  const jsSdk: any = d.createElement(s);
  jsSdk.id = id;
  jsSdk.src = 'https://connect.facebook.net/en_US/sdk.js';
  jsSdk.async = true;
  jsSdk.defer = true;

  firstJs.parentNode?.insertBefore(jsSdk, firstJs);
};

export const initFacebookSDK = ({
  appId,
  version = 'v14.0',
}: {
  appId: string;
  version?: string;
}): Promise<void> => {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId,
        version,
        cookie: true,
        xfbml: true,
      });

      resolve();
    };

    loadFacebookSdk(document, SCRIPT, SDK_ID);
  });
};

export const login = (isStore = false): Promise<FacebookLoginSuccessResult> =>
  new Promise((resolve) => {
    const isRedirectLogin = isBuildInBrowser();
    const scope = isStore
      ? facebookStoreScopes.join(',')
      : facebookScopes.join(',');

    if (isRedirectLogin) {
      window.location.href = `https://www.facebook.com/dialog/oauth?client_id=${
        process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
      }&redirect_uri=${getEnvLink()}&display=popup&scope=${scope}&response_type=token`;
    } else {
      window.FB.getLoginStatus((response) => {
        if (response.status === FacebookLoginStatuses.Connected) {
          return handleLoginStatus(response).then(resolve);
        }

        window.FB.login(
          (response) => handleLoginStatus(response).then(resolve),
          {
            scope,
          }
        );
      });
    }
  });

export const loginForReStreaming = (): Promise<FacebookLoginSuccessResult> =>
  new Promise((resolve) => {
    window.FB.login((response) => handleLoginStatus(response).then(resolve), {
      scope: facebookScopesForReStreaming.join(','),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      default_audience: 'everyone',
    });
  });

const handleLoginStatus = (response) =>
  new Promise((resolve) => {
    if (response.status === FacebookLoginStatuses.Connected) {
      getProfile().then((profile: Record<string, unknown>) =>
        resolve(
          prepareResponse({
            ...profile,
            ...response.authResponse,
          })
        )
      );
    }
  });

const getProfile = () =>
  new Promise((resolve) => {
    window.FB.api(
      '/me',
      'get',
      {
        fields: 'email,name,id,first_name,last_name,picture',
      },
      resolve
    );
  });

export const getPages = (): Promise<FacebookGetPagesSuccessResult> =>
  new Promise((resolve) => {
    window.FB.api(
      '/me/accounts',
      'get',
      {
        fields: 'id,name',
      },
      resolve
    );
  });

const prepareResponse = (response): FacebookLoginSuccessResult => ({
  profile: {
    id: response?.id,
    email: response?.email,
    firstName: response?.first_name,
    lastName: response?.last_name,
    name: response?.name,
    profilePicURL: response?.picture?.data?.url,
  },
  token: {
    accessToken: response?.accessToken,
    expiresAt: response?.data_access_expiration_time,
  },
});

export const logOut = (): Promise<any> => {
  return new Promise((resolve) => {
    window.FB?.getLoginStatus((response) => {
      if (response?.status === FacebookLoginStatuses.Connected) {
        window.FB.logout(resolve);
      } else {
        resolve(null);
      }
    });
  });
};
