import { getUA } from 'react-device-detect';

export const isFacebookApp = (): boolean => {
  return getUA.indexOf('FBAN') > -1 || getUA.indexOf('FBAV') > -1;
};

export const isInstagramApp = (): boolean => {
  return getUA.indexOf('Instagram') > -1;
};

export const isBuildInBrowser = (): boolean => {
  return isFacebookApp() || isInstagramApp();
};

export const isIOS15 = (): boolean => {
  return getUA.toLowerCase().includes('iphone os 15');
};
